<template>
  <Breadcrumb v-if="detailTitle" :detailTitle="detailTitle.toUpperCase()" />
  <Breadcrumb v-else detailTitle="&nbsp;" />
  <div class="card bg-light mb-2">
    <div class="card-body p-2">
      <div class="d-flex flex-wrap justify-content-between">
        <div
          class="mb-2 mb-md-0"
          :class="{ 'col-3': activeTabSearchSelectForm }"
        >
          <input
            type="text"
            class="form-control"
            @keyup="tabDataSearch()"
            :placeholder="
              $t('Header.Search', {}, { locale: this.$store.state.activeLang })
            "
            v-model="query"
            v-show="!activeTabSearchSelectForm"
          />
          <div v-show="activeTabSearchSelectForm">
            <FormSelect
              type="SelectWithRemoteDataAndInternalSearch"
              @onChange="onChangeCustomObject"
              :resetSelect="customObjectSelectReset"
              requestUrl="/Lcdp-SummaryCustomObjects"
              :isGatewayRequest="true"
            />
          </div>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-success mb-2 mb-md-0 mark-all"
            @click="checkAll()"
          >
            <i class="bi bi-toggle-on"></i>
            {{
              $t(
                "PermissionGroups.CheckAll",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
          <button
            type="button"
            class="btn btn-danger ms-1 mb-2 mb-md-0 unmark-all"
            @click="unCheckAll()"
          >
            <i class="bi bi-toggle-off"></i>
            {{
              $t(
                "PermissionGroups.UnCheckAll",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
          <button
            type="button"
            class="btn btn-info ms-1 mb-2 mb-md-0 btn-save"
            :disabled="!isChanged"
            @click="savePermissions()"
          >
            <span
              ><i class="bi bi-save"></i>
              {{
                $t("Buttons.Save", {}, { locale: this.$store.state.activeLang })
              }}</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            @click="tabClick('customObjects')"
            class="nav-link active"
            id="customobject-tab"
            data-bs-toggle="tab"
            data-bs-target="#customObjects"
            type="button"
            role="tab"
            aria-selected="true"
          >
            {{
              $t(
                "PermissionGroups.CustomObjects",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            @click="tabClick('actions')"
            class="nav-link"
            id="action-tab"
            data-bs-toggle="tab"
            data-bs-target="#actions"
            type="button"
            role="tab"
            aria-controls="action"
            aria-selected="false"
          >
            {{
              $t(
                "PermissionGroups.Actions",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            @click="tabClick('viewfilters')"
            class="nav-link"
            id="viewfilter-tab"
            data-bs-toggle="tab"
            data-bs-target="#viewfilters"
            type="button"
            role="tab"
            aria-controls="viewfilter"
            aria-selected="false"
          >
            {{
              $t(
                "PermissionGroups.ViewFilters",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            @click="tabClick('pagedesigners')"
            class="nav-link"
            id="pagelayout-tab"
            data-bs-toggle="tab"
            data-bs-target="#pagedesigners"
            type="button"
            role="tab"
            aria-controls="pagelayout"
            aria-selected="false"
          >
            {{
              $t(
                "PermissionGroups.PageLayouts",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            @click="tabClick('dashboards')"
            class="nav-link"
            id="pagedesigner-tab"
            data-bs-toggle="tab"
            data-bs-target="#dashboards"
            type="button"
            role="tab"
            aria-controls="dashboard"
            aria-selected="false"
          >
            {{
              $t(
                "PermissionGroups.DashboardReports",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            @click="tabClick('xtraReports')"
            class="nav-link"
            id="pagedesigner-tab"
            data-bs-toggle="tab"
            data-bs-target="#xtraReports"
            type="button"
            role="tab"
            aria-controls="xtraReport"
            aria-selected="false"
          >
            {{
              $t(
                "PermissionGroups.XtraReports",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            @click="tabClick('custompages')"
            class="nav-link"
            id="custompage-tab"
            data-bs-toggle="tab"
            data-bs-target="#custompages"
            type="button"
            role="tab"
            aria-controls="custompage"
            aria-selected="false"
          >
            {{
              $t(
                "PermissionGroups.CustomPage",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="tab-content mt-1">
        <div
          class="tab-pane fade show active"
          id="customObjects"
          role="tabpanel"
          aria-labelledby="customobject-tab"
        >
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-pg">
              <thead>
                <tr>
                  <th width="100">
                    {{
                      $t(
                        "PermissionGroups.AddRemove",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "PermissionGroups.CustomObject",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "PermissionGroups.Create",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "PermissionGroups.Read",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "PermissionGroups.DetailRead",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "PermissionGroups.FileRead",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "PermissionGroups.DataExport",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "PermissionGroups.Update",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "PermissionGroups.Delete",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "PermissionGroups.History",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody class="search-select-tbody">
                <tr
                  v-for="item in permissionGroup.customObjects"
                  :key="item.key"
                  :data-coid="item.key"
                >
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        @change="rowCheckUnCheck"
                        :checked="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.allPermissionChecked)[0]
                        "
                      />
                    </div>
                  </td>
                  <td class="pg-title align-middle text-break">
                    {{ item.value }}
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input create"
                        type="checkbox"
                        :data-oldvalue="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isCreateAllowed)[0]
                        "
                        @change="triggerCheck"
                        :checked="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isCreateAllowed)[0]
                        "
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input read"
                        type="checkbox"
                        :data-oldvalue="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isReadAllowed)[0]
                        "
                        @change="triggerCheck"
                        :checked="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isReadAllowed)[0]
                        "
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input detail-read"
                        type="checkbox"
                        :data-oldvalue="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isReadDetailAllowed)[0]
                        "
                        @change="triggerCheck"
                        :checked="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isReadDetailAllowed)[0]
                        "
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input file-read"
                        type="checkbox"
                        :data-oldvalue="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isReadDocumentsAllowed)[0]
                        "
                        @change="triggerCheck"
                        :checked="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isReadDocumentsAllowed)[0]
                        "
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input data-export"
                        type="checkbox"
                        :data-oldvalue="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isDataExportsAllowed)[0]
                        "
                        @change="triggerCheck"
                        :checked="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isDataExportsAllowed)[0]
                        "
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input update"
                        type="checkbox"
                        :data-oldvalue="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isUpdateAllowed)[0]
                        "
                        @change="triggerCheck"
                        :checked="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isUpdateAllowed)[0]
                        "
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input delete"
                        type="checkbox"
                        :data-oldvalue="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isDeleteAllowed)[0]
                        "
                        @change="triggerCheck"
                        :checked="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isDeleteAllowed)[0]
                        "
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input history"
                        type="checkbox"
                        :data-oldvalue="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isHistoryAllowed)[0]
                        "
                        @change="triggerCheck"
                        :checked="
                          this.checkedCustomObjects
                            .filter((f) => f.customObjectPublicId === item.key)
                            .map((x) => x.isHistoryAllowed)[0]
                        "
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="actions"
          role="tabpanel"
          aria-labelledby="action-tab"
        >
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-pg">
              <thead>
                <tr>
                  <th class="text-start">
                    {{
                      $t(
                        "PermissionGroups.Action",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th width="150">
                    {{
                      $t(
                        "PermissionGroups.Process",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in permissionGroup.actions"
                  :key="index"
                >
                  <td class="pg-title align-middle text-break">
                    {{ item }}
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :data-oldvalue="
                          this.checkedActions.some((s) => s === item)
                        "
                        @change="triggerCheck"
                        :checked="this.checkedActions.some((s) => s === item)"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="viewfilters"
          role="tabpanel"
          aria-labelledby="viewfilter-tab"
        >
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-pg">
              <thead>
                <tr>
                  <th class="text-start" width="350">
                    {{
                      $t(
                        "Users.CustomObject",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th class="text-start">
                    {{
                      $t(
                        "PermissionGroups.ViewFilter",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th width="150">
                    {{
                      $t(
                        "PermissionGroups.IsAllowed",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody class="search-select-tbody">
                <template v-for="item in this.viewFiltersGroupByCustomObject">
                  <tr
                    v-for="(subitem, iSub) in item.Items"
                    :key="subitem.publicId"
                    :data-id="subitem.publicId"
                    :data-coid="item.CustomObjectPublicId"
                  >
                    <td
                      class="pg-title align-middle text-break"
                      v-if="iSub === 0"
                      :rowspan="item.RowSpan"
                    >
                      {{ subitem.customObjectName }}
                    </td>
                    <td class="pg-title align-middle text-break">
                      {{ subitem.name }}
                    </td>
                    <td>
                      <div
                        class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :data-oldvalue="
                            this.checkedViewFilters.some(
                              (s) => s === subitem.publicId
                            )
                          "
                          @change="triggerCheck"
                          :checked="
                            this.checkedViewFilters.some(
                              (s) => s === subitem.publicId
                            )
                          "
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pagedesigners"
          role="tabpanel"
          aria-labelledby="pagelayout-tab"
        >
          <table class="table table-bordered table-hover table-pg">
            <thead>
              <tr>
                <th class="text-start" width="350">
                  {{
                    $t(
                      "Users.CustomObject",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
                <th class="text-start">
                  {{
                    $t(
                      "PermissionGroups.PageLayout",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
                <th width="150">
                  {{
                    $t(
                      "PermissionGroups.IsAllowed",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
              </tr>
            </thead>
            <tbody class="search-select-tbody">
              <template v-for="item in this.pageLayoutsGroupByCustomObject">
                <tr
                  v-for="(subitem, iSub) in item.Items"
                  :key="subitem.publicId"
                  :data-id="subitem.publicId"
                  :data-coid="item.CustomObjectPublicId"
                >
                  <td
                    class="pg-title align-middle text-break"
                    v-if="iSub === 0"
                    :rowspan="item.RowSpan"
                  >
                    {{ subitem.customObjectName }}
                  </td>
                  <td class="pg-title align-middle text-break">
                    {{ subitem.name }}
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :data-oldvalue="
                          this.checkedPageDesigners.some(
                            (s) => s === subitem.publicId
                          )
                        "
                        @change="triggerCheck"
                        :checked="
                          this.checkedPageDesigners.some(
                            (s) => s === subitem.publicId
                          )
                        "
                      />
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div
          class="tab-pane fade"
          id="dashboards"
          role="tabpanel"
          aria-labelledby="dashboard-tab"
        >
          <table class="table table-bordered table-hover table-pg">
            <thead>
              <tr>
                <th width="50">
                  {{
                    $t(
                      "Users.DashboardReportOrder",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
                <th>
                  {{
                    $t(
                      "Users.DashboardReportName",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
                <th>
                  {{
                    $t(
                      "Users.DashboardReportFormulaName",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
                <th width="150">
                  {{
                    $t(
                      "Users.IsAllowed",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in permissionGroup.dashboards"
                :key="item.publicId"
                :data-id="item.publicId"
              >
                <td class="pg-title align-middle text-break text-center">
                  {{ item.order }}
                </td>
                <td class="pg-title align-middle text-break text-center">
                  {{ item.name }}
                </td>
                <td class="pg-title align-middle text-break text-center">
                  {{ item.formulaName }}
                </td>
                <td>
                  <div
                    class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :data-oldvalue="
                        this.checkedDashboards.some((s) => s === item.publicId)
                      "
                      @change="triggerCheck"
                      :checked="
                        this.checkedDashboards.some((s) => s === item.publicId)
                      "
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="tab-pane fade"
          id="xtraReports"
          role="tabpanel"
          aria-labelledby="xtraReport-tab"
        >
          <table class="table table-bordered table-hover table-pg">
            <thead>
              <tr>
                <th width="50">
                  {{
                    $t(
                      "Users.XtraReportOrder",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
                <th>
                  {{
                    $t(
                      "Users.XtraReportName",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
                <th>
                  {{
                    $t(
                      "Users.XtraReportFormulaName",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
                <th width="150">
                  {{
                    $t(
                      "Users.IsAllowed",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in permissionGroup.xtraReports"
                :key="item.publicId"
                :data-id="item.publicId"
              >
                <td class="pg-title align-middle text-break text-center">
                  {{ item.order }}
                </td>
                <td class="pg-title align-middle text-break text-center">
                  {{ item.name }}
                </td>
                <td class="pg-title align-middle text-break text-center">
                  {{ item.formulaName }}
                </td>
                <td>
                  <div
                    class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :data-oldvalue="
                        this.checkedXtraReports.some((s) => s === item.publicId)
                      "
                      @change="triggerCheck"
                      :checked="
                        this.checkedXtraReports.some((s) => s === item.publicId)
                      "
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="tab-pane fade"
          id="custompages"
          role="tabpanel"
          aria-labelledby="custompage-tab"
        >
          <table class="table table-bordered table-hover table-pg">
            <thead>
              <tr>
                <th class="text-start">
                  {{
                    $t(
                      "PermissionGroups.CustomPage",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
                <th width="150">
                  {{
                    $t(
                      "PermissionGroups.IsAllowed",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in permissionGroup.customPages"
                :key="item.publicId"
                :data-id="item.publicId"
              >
                <td class="pg-title align-middle text-break">
                  {{ item.name }}
                </td>
                <td>
                  <div
                    class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :data-oldvalue="
                        this.checkedCustomPages.some((s) => s === item.publicId)
                      "
                      @change="triggerCheck"
                      :checked="
                        this.checkedCustomPages.some((s) => s === item.publicId)
                      "
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <AlertModal
    messageKey="Components.PermissionAlertModal.Message"
    @yes="permissionErrorYes"
    @no="permissionErrorNo"
  />
</template>
<script>
import { createToast } from "mosha-vue-toastify";
import AlertModal from "@//components/custom/alert/AlertModal.vue";
import $ from "jquery";
export default {
  name: "PermissionGroupGroups",
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .unmark-all, .mark-all",
      isChanged: false,
      permissionGroup: [],
      checkedCustomObjects: [],
      checkedActions: [],
      checkedViewFilters: [],
      checkedPageDesigners: [],
      checkedDashboards: [],
      checkedXtraReports: [],
      checkedCustomPages: [],
      activeTabSearchSelectForm: true,
      customObjectSelectReset: false,
      pageLayoutsGroupByCustomObject: [],
      viewFiltersGroupByCustomObject: [],
      detailTitle: "",
      query: "",
      model: {
        publicId: this.$route.params.permissionGroupId,
        // isAdmin : true,
        isRestrictedPermissionGroup: false,
        isUser: false,
        permissions: [],
      },
      activeTab: "customObjects",
      nextTab: "",
      oldTab: "",
    };
  },
  components: {
    AlertModal,
  },
  methods: {
    permissionErrorYes() {
      var nextTab = $(String.format('[data-bs-target="#{0}"]', this.nextTab));
      nextTab.attr("data-forcenext", "true").click();
    },
    permissionErrorNo() {
      var oldTab = $(String.format('[data-bs-target="#{0}"]', this.oldTab));
      oldTab.removeAttr("data-forcenext").click();
    },
    tabDataSearch() {
      var value = this.query.toLowerCase();
      $(String.format("#{0} table tbody tr", this.activeTab)).filter(
        function () {
          $(this).toggle(
            $(this)
              .find(".pg-title")
              .text()
              .trim()
              .toLowerCase()
              .indexOf(value) > -1
          );
        }
      );
    },
    tabClick(tabName) {
      this.oldTab = this.activeTab;
      this.activeTab = tabName;
      this.activeTabSearchSelectForm =
        tabName == "viewfilters" ||
        tabName === "pagedesigners" ||
        tabName === "customObjects";
      if (this.activeTabSearchSelectForm) {
        // this.customObjectSelectReset = !this.customObjectSelectReset;
      } else {
        this.query = "";
        this.tabDataSearch();
      }

      var changedDataOldTab =
          $(String.format("#{0} table tbody tr .has-changed", this.oldTab))
            .length > 0,
        tabItem = $(String.format('[data-bs-target="#{0}"]', tabName));
      if (changedDataOldTab) {
        if (!tabItem.attr("data-forcenext")) {
          this.nextTab = tabName;
          $("#message-modal").click();
        }
      }
      tabItem.removeAttr("data-forcenext");
    },
    onChangeCustomObject(selected) {
      var records = $(".search-select-tbody tr");
      records.show();

      if (String.isNullOrWhiteSpace(selected.key)) {
        return;
      }

      if (!Array.isArray(selected)) {
        records.each(function (i, v) {
          var record = $(v);
          if (record.data("coid") !== selected.key) {
            record.hide();
          }
        });
      }
    },
    triggerCheck(event) {
      this.isChanged = true;
      var src = $(event.srcElement).data("oldvalue"),
        srcChecked = $(event.srcElement).is(":checked");
      if (src === srcChecked) {
        $(event.srcElement).removeClass("has-changed");
      } else {
        $(event.srcElement).addClass("has-changed");
      }
      if (this.activeTab === "customObjects") {
        var tr = $(event.srcElement).parents("tr");
        var isCreateAllowed = tr
          .find("td:eq(2) .form-check-input")
          .is(":checked");
        var isReadAllowed = tr
          .find("td:eq(3) .form-check-input")
          .is(":checked");
        var isReadDetailAllowed = tr
          .find("td:eq(4) .form-check-input")
          .is(":checked");
        var isReadDocumentsAllowed = tr
          .find("td:eq(5) .form-check-input")
          .is(":checked");
        var isDataExportsAllowed = tr
          .find("td:eq(6) .form-check-input")
          .is(":checked");
        var isUpdateAllowed = tr
          .find("td:eq(7) .form-check-input")
          .is(":checked");
        var isDeleteAllowed = tr
          .find("td:eq(8) .form-check-input")
          .is(":checked");
        var isHistoryAllowed = tr
          .find("td:eq(9) .form-check-input")
          .is(":checked");
        if (
          isCreateAllowed &&
          isDataExportsAllowed &&
          isDeleteAllowed &&
          isHistoryAllowed &&
          isReadAllowed &&
          isReadDetailAllowed &&
          isReadDocumentsAllowed &&
          isUpdateAllowed
        ) {
          tr.find("td:first .form-check-input").prop("checked", true);
        } else {
          tr.find("td:first .form-check-input").prop("checked", false);
        }
      }
    },
    checkAll() {
      this.isChanged = true;
      var checks = $(
        String.format(
          "#{0} table tbody tr:not(.hidden):visible .form-check-input",
          this.activeTab
        )
      );
      checks.prop("checked", true);
      checks.addClass("has-changed");
    },
    unCheckAll() {
      this.isChanged = true;
      var checks = $(
        String.format(
          "#{0} table tbody tr:not(.hidden):visible .form-check-input",
          this.activeTab
        )
      );
      checks.prop("checked", false);
      checks.addClass("has-changed");
    },
    rowCheckUnCheck(event) {
      this.isChanged = true;
      $(event.srcElement)
        .parents("tr")
        .find(".form-check-input:not(:first)")
        .prop("checked", event.target.checked);
      $(event.srcElement)
        .parents("tr")
        .find(".form-check-input:not(:first)")
        .addClass("has-changed");
    },
    getPermissionDetail() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-PermissionGroups?id={0}",
            this.$route.params.permissionGroupId
          )
        )
        .then((response) => {
          this.permissionGroup = response.data;
          this.detailTitle = response.data.name;

          Object.keys(this.permissionGroup.permissions).map((key) => {
            var currentRecord = response.data.permissions[key];

            var customObjectId = currentRecord.customObjectPublicId;
            if (
              !String.isNullOrWhiteSpace(customObjectId) &&
              (currentRecord.isCreateAllowed ||
                currentRecord.isDataExportsAllowed ||
                currentRecord.isDeleteAllowed ||
                currentRecord.isHistoryAllowed ||
                currentRecord.isReadAllowed ||
                currentRecord.isReadDetailAllowed ||
                currentRecord.isReadDocumentsAllowed ||
                currentRecord.isUpdateAllowed)
            ) {
              this.checkedCustomObjects.push({
                customObjectPublicId: customObjectId,
                isCreateAllowed: currentRecord.isCreateAllowed,
                isDataExportsAllowed: currentRecord.isDataExportsAllowed,
                isDeleteAllowed: currentRecord.isDeleteAllowed,
                isHistoryAllowed: currentRecord.isHistoryAllowed,
                isReadAllowed: currentRecord.isReadAllowed,
                isReadDetailAllowed: currentRecord.isReadDetailAllowed,
                isReadDocumentsAllowed: currentRecord.isReadDocumentsAllowed,
                isUpdateAllowed: currentRecord.isUpdateAllowed,
                allPermissionChecked:
                  currentRecord.isCreateAllowed &&
                  currentRecord.isDataExportsAllowed &&
                  currentRecord.isDeleteAllowed &&
                  currentRecord.isHistoryAllowed &&
                  currentRecord.isReadAllowed &&
                  currentRecord.isReadDetailAllowed &&
                  currentRecord.isReadDocumentsAllowed &&
                  currentRecord.isUpdateAllowed,
              });
            }

            var actionName = currentRecord.actionName;
            if (
              !String.isNullOrWhiteSpace(actionName) &&
              currentRecord.isAllowed
            ) {
              this.checkedActions.push(actionName);
            }

            var viewFilterId = currentRecord.viewFilterPublicId;
            if (
              !String.isNullOrWhiteSpace(viewFilterId) &&
              currentRecord.isAllowed
            ) {
              this.checkedViewFilters.push(viewFilterId);
            }

            var pageLayoutId = currentRecord.pageLayoutPublicId;
            if (
              !String.isNullOrWhiteSpace(pageLayoutId) &&
              currentRecord.isAllowed
            ) {
              this.checkedPageDesigners.push(pageLayoutId);
            }

            var customPageId = currentRecord.customPagePublicId;

            if (
              !String.isNullOrWhiteSpace(customPageId) &&
              currentRecord.isAllowed
            ) {
              this.checkedCustomPages.push(customPageId);
            }

            var dashboardId = currentRecord.dashboardPublicId;
            if (
              !String.isNullOrWhiteSpace(dashboardId) &&
              currentRecord.isAllowed
            ) {
              this.checkedDashboards.push(dashboardId);
            }
            var xtraReportPublicId = currentRecord.xtraReportPublicId;
            if (
              !String.isNullOrWhiteSpace(xtraReportPublicId) &&
              currentRecord.isAllowed
            ) {
              this.checkedXtraReports.push(xtraReportPublicId);
            }
          });

          this.permissionGroup.pageLayouts.forEach((element) => {
            var coid = element.customObjectPublicId,
              arrayItem = this.pageLayoutsGroupByCustomObject.find(
                (f) => f.CustomObjectPublicId === coid
              );
            if (arrayItem == null) {
              this.pageLayoutsGroupByCustomObject.push({
                CustomObjectPublicId: coid,
                CustomObjectName: element.customObjectName,
                Items: [element],
                RowSpan: 1,
              });
            } else {
              arrayItem.RowSpan = arrayItem.RowSpan + 1;
              arrayItem.Items.push(element);
            }
          });

          this.permissionGroup.viewFilters.forEach((element) => {
            var coid = element.customObjectPublicId,
              arrayItem = this.viewFiltersGroupByCustomObject.find(
                (f) => f.CustomObjectPublicId === coid
              );
            if (arrayItem == null) {
              this.viewFiltersGroupByCustomObject.push({
                CustomObjectPublicId: coid,
                CustomObjectName: element.customObjectName,
                Items: [element],
                RowSpan: 1,
              });
            } else {
              arrayItem.RowSpan = arrayItem.RowSpan + 1;
              arrayItem.Items.push(element);
            }
          });
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    savePermissions() {
      var button = $(String.format(".btn-save")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      switch (this.activeTab) {
        case "customObjects":
          var customObjectsArray = [];
          $(String.format("#{0} table tbody tr", this.activeTab)).each(
            function (i, v) {
              if ($(v).find(".has-changed").length > 0) {
                var customObjectPublicId = $(this).data("coid");
                var isCreateAllowed = $(v)
                  .find("td:eq(2) .form-check-input")
                  .is(":checked");
                var isReadAllowed = $(v)
                  .find("td:eq(3) .form-check-input")
                  .is(":checked");
                var isReadDetailAllowed = $(v)
                  .find("td:eq(4) .form-check-input")
                  .is(":checked");
                var isReadDocumentsAllowed = $(v)
                  .find("td:eq(5) .form-check-input")
                  .is(":checked");
                var isDataExportsAllowed = $(v)
                  .find("td:eq(6) .form-check-input")
                  .is(":checked");
                var isUpdateAllowed = $(v)
                  .find("td:eq(7) .form-check-input")
                  .is(":checked");
                var isDeleteAllowed = $(v)
                  .find("td:eq(8) .form-check-input")
                  .is(":checked");
                var isHistoryAllowed = $(v)
                  .find("td:eq(9) .form-check-input")
                  .is(":checked");

                customObjectsArray.push({
                  CustomObjectPublicId: customObjectPublicId,
                  IsCreateAllowed: isCreateAllowed,
                  IsDeleteAllowed: isDeleteAllowed,
                  IsReadAllowed: isReadAllowed,
                  IsReadDetailAllowed: isReadDetailAllowed,
                  IsReadDocumentsAllowed: isReadDocumentsAllowed,
                  IsDataExportsAllowed: isDataExportsAllowed,
                  IsUpdateAllowed: isUpdateAllowed,
                  IsHistoryAllowed: isHistoryAllowed,
                });
              }
            }
          );
          this.model.Permissions = customObjectsArray;
          break;
        case "actions":
          var actionArray = [];
          $(String.format("#{0} table tbody tr", this.activeTab)).each(
            function (i, v) {
              if ($(v).find(".has-changed").length > 0) {
                var isAllowed = $(v)
                  .find(".form-check-input:last")
                  .is(":checked");
                actionArray.push({
                  ActionName: $(v).find("td:eq(0)").text().trim(),
                  IsAllowed: isAllowed,
                });
              }
            }
          );
          this.model.Permissions = actionArray;
          break;
        case "viewfilters":
          var viewFilterArray = [];
          $(String.format("#{0} table tbody tr", this.activeTab)).each(
            function (i, v) {
              if ($(v).find(".has-changed").length > 0) {
                var isAllowed = $(v)
                  .find(".form-check-input:last")
                  .is(":checked");
                var dataId = $(this).data("id");
                var coId = $(this).data("coid");
                viewFilterArray.push({
                  ViewFilterPublicId: dataId,
                  CustomObjectPublicId: coId,
                  IsAllowed: isAllowed,
                });
              }
            }
          );
          this.model.Permissions = viewFilterArray;
          break;
        case "pagedesigners":
          var pageLayoutArray = [];
          $(String.format("#{0} table tbody tr", this.activeTab)).each(
            function (i, v) {
              if ($(v).find(".has-changed").length > 0) {
                var isAllowed = $(v)
                  .find(".form-check-input:last")
                  .is(":checked");
                var dataId = $(this).data("id");
                var coId = $(this).data("coid");
                pageLayoutArray.push({
                  PageLayoutPublicId: dataId,
                  CustomObjectPublicId: coId,
                  IsAllowed: isAllowed,
                });
              }
            }
          );
          this.model.Permissions = pageLayoutArray;
          break;
        case "dashboards":
          var dashboardArray = [];
          $(String.format("#{0} table tbody tr", this.activeTab)).each(
            function (i, v) {
              if ($(v).find(".has-changed").length > 0) {
                var isAllowed = $(v)
                  .find(".form-check-input:last")
                  .is(":checked");
                var dataId = $(this).data("id");
                dashboardArray.push({
                  DashboardPublicId: dataId,
                  IsAllowed: isAllowed,
                });
              }
            }
          );
          this.model.Permissions = dashboardArray;
          break;
        case "xtraReports":
          var xtraReportArray = [];
          $(String.format("#{0} table tbody tr", this.activeTab)).each(
            function (i, v) {
              if ($(v).find(".has-changed").length > 0) {
                var isAllowed = $(v)
                  .find(".form-check-input:last")
                  .is(":checked");
                var dataId = $(this).data("id");
                xtraReportArray.push({
                  xtraReportPublicId: dataId,
                  IsAllowed: isAllowed,
                });
              }
            }
          );
          this.model.Permissions = xtraReportArray;
          break;
        case "custompages":
          var custompageArray = [];
          $(String.format("#{0} table tbody tr", this.activeTab)).each(
            function (i, v) {
              if ($(v).find(".has-changed").length > 0) {
                var isAllowed = $(v)
                  .find(".form-check-input:last")
                  .is(":checked");
                var dataId = $(this).data("id");
                custompageArray.push({
                  CustomPagePublicId: dataId,
                  IsAllowed: isAllowed,
                });
              }
            }
          );
          this.model.Permissions = custompageArray;
          break;
        default:
          return;
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-UserPermissionSave", { ...this.model })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.model.permissions = [];

            createToast(
              this.$t(
                "Messages.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: "true",
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );

            //değişiklikleri siliyorum. tab validasyonu sağlıklı çalışması için.
            $(
              String.format("#{0} table tbody tr .has-changed", this.activeTab)
            ).each(function (i, v) {
              $(v)
                .data("oldvalue", !$(v).data("oldvalue"))
                .removeClass("has-changed");
            });

            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getPermissionDetail();
  },
};
</script>
